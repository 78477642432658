<template>
  <div
    class="video-feed"
  >
    <h1
      v-if="!ready && !streaming"
      class="loading"
    >
      Loading...
    </h1>
    <div
      id="videowrap"
      :class="{ streaming: streaming }"
    >
      <video
        id="myvideo"
        class="rounded centered"
        autoplay
        muted
        playsinline
      />
    </div>

    <streamTimer
      class="timer"
      :streaming="streaming"
      :start="start"
      :last-tick="lastTick"
    />

    <LiveIcon :live="streaming" />

    <div
      v-if="showPreferences"
      class="publisher-preferences"
    >
      <h1>Streaming Preference</h1>

      <hr>

      <div id="main-container">
        <div class="c-options">
          <!-- TODO: Replace divs with actual radios -->
          <div class="form-group">
            <span class="stream-preference stream-privacy">
              <div
                class="preference-option"
                @click="privacy = PRIVACY_MEMBERS"
              >
                <span
                  class="radio-button"
                  :class="{ selected: privacy == PRIVACY_MEMBERS }"
                />
                All Members
              </div>
              <div
                class="preference-option"
                @click="privacy = PRIVACY_FOLLOWERS"
              >
                <span
                  class="radio-button"
                  :class="{ selected: privacy == PRIVACY_FOLLOWERS }"
                />
                Followers Only
              </div>
            </span>
          </div>

          <div
            v-if="hasDeviceChoices"
            id="devices"
            class="col-options"
          >
            <div
              v-if="showVideoChoices"
              class="form-group"
            >
              <label class="field-name">
                Camera:
              </label>
              <select
                id="change-videoinput"
                v-model="videoDeviceId"
              >
                <option
                  v-for="(device, index) in videoDevices"
                  :key="index"
                  :value="device.deviceId"
                >
                  {{ device.label }}
                </option>
              </select>
            </div>
            <div
              v-if="showAudioChoices"
              class="form-group"
            >
              <label class="field-name">
                Microphone:
              </label>
              <select
                id="change-audioinput"
                v-model="audioDeviceId"
              >
                <option
                  v-for="(device, index) in audioDevices"
                  :key="index"
                  :value="device.deviceId"
                >
                  {{ device.label }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <hr>
    </div>
    <div class="actions">
      <button
        v-if="!streaming"
        id="start-stream"
        :disabled="!ready"
        class="go-live-cta"
        @click="startStreaming()"
      >
        Go Live on SG
      </button>
      <button
        v-if="streaming"
        class="end-stream"
        @click="stopStreaming()"
      >
        End stream
      </button>
      <button
        v-if="isMobile && !streaming"
        class="switch-camera"
        @click="switchCamera()"
      >
        Switch
      </button>
      <button
        v-if="streaming"
        class="mute"
        :class="{ muted: muted }"
        @click="toggleMute()"
      >
        Mute
      </button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { find } from 'lodash-es';
import { StreamModule } from 'streaming-module';
import Vue from 'vue';
import {
mapMutations,
mapState,
} from 'vuex';

import LiveIcon from './LiveIcon.vue';
import StreamTimer from './StreamTimer.vue';

import urlSpeedTest from 'common/URLSpeedTest';
import Privacy from 'main/mixins/Privacy';
import { Stream } from 'main/models/';

export default Vue.extend({
  components: {
    LiveIcon,
    StreamTimer,
  },

  mixins: [
    Privacy,
  ],

  props: {},

  data() {
    return {
      audioDeviceId: '',
      audioDevices: [],
      lastTick: null,
      latestComment: null,
      localStreamInitialized: false,
      isMobile: false,
      muted: false,
      ready: false,
      roomName: null,
      saveVideo: false,
      showLiveChat: false,
      start: null,
      stream: null,
      streaming: false,
      streamModule: null,
      streamType: null,
      videoDeviceId: '',
      videoDevices: [],
    };
  },

  computed: {
    ...mapState({
      videoSettings(state) {
        const { mediaStream } = state.live;
        if (!mediaStream) {
          return null;
        }
        const [videoTrack] = mediaStream.getVideoTracks();
        if (!videoTrack) {
          return null;
        }
        return videoTrack.getSettings();
      },
    }),
    hasDeviceChoices() {
      const hasVideo = (!this.isMobile && this.videoDevices.length > 1);
      const hasAudio = (this.audioDevices.length > 1);
      return hasVideo || hasAudio;
    },
    showVideoChoices() {
      return !this.isMobile && this.videoDevices.length > 1;
    },
    showAudioChoices() {
      return this.audioDevices.length > 1;
    },
    showPreferences() {
      return (this.ready && !this.streaming);
    },
  },

  watch: {
    streamType() {
      if (
        this.streamModule
        && this.streamModule.streamType !== this.streamType
      ) {
        this.initStreamModule();
      }
    },

    videoSettings() {
      if (this.videoSettings && !this.ready) {
        this.ready = true;
        this.SET_NATIVE_VIDEO_SETTINGS(this.videoSettings);
        this.$emit('initialized');
      }
    },

    audioDeviceId() {
      if (!this.ready || this.isMobile) {
        return;
      }

      SG.debug(
        `Switch devices to ${this.audioDeviceId} ${this.videoDeviceId}`,
      );

      this.streamModule.switchDevices(this.audioDeviceId, false);
    },

    videoDeviceId() {
      if (!this.ready || this.isMobile) {
        return;
      }

      SG.debug(
        `Switch devices to ${this.audioDeviceId} ${this.videoDeviceId}`,
      );

      this.streamModule.switchDevices(false, this.videoDeviceId);
    },
  },

  created() {
    // Only support Agora now.
    this.streamType = 'agora';
  },

  async mounted() {
    this.initStreamModule();
  },

  methods: {
    ...mapMutations([
      'CLOSE_STREAM_DIALOG',
      'SET_STREAMING_TRUE',
      'SET_STREAMING_FALSE',
      'SET_NATIVE_VIDEO_SETTINGS',
    ]),

    async initStreamModule() {
      this.streamModule = new StreamModule(this.streamType);
      if (window.debugFrontend) {
        window.streamModule = this.streamModule;
      }
      this.isMobile = this.streamModule.isMobile;
      this.$emit('initializing', this.streamModule);

      this.$nextTick(() => {
        let token = window.localStorage.getItem('access-token');
        if (!token) {
          token = (+new Date()).toString(36);
          window.localStorage.setItem('access-token', token);
        }

        this.streamModule.init({
          thumbEnabled: true,
          videoSave: true,
          videoElId: 'myvideo',
          showLikes: false,
          debug: true,
          videoFrameRate: 30,

          showErrorMessage: (message) => {
            SG.userError(message);
            this.cleanUp();
          },

          showInfoMessage: (message) => {
            SG.userMessage(message);
          },

          onInfoStreamer: (event) => {
            if (event.data_ext && event.data_ext.wait_status === 1) {
              SG.userError(
                'Connection problems, stream is likely not viewable',
              );
            } else if (event.data_ext.wait_status === 0) {
              SG.userSuccess('Connection restored!');
            }
          },

          onLocalStreamInit: () => {
            this.localStreamInitialized = true;
            this.$store.commit(
              'SET_MEDIA_STREAM',
              this.streamModule.localStream,
            );
          },

          onServerDataValidateError: (message) => {
            SG.debug(message);
            SG.userError(message);
            this.cleanUp();
          },

          onStreamError: (error) => {
            SG.debug(error);
            SG.userError(error);
            this.cleanUp();
          },

          onStreamTick: (start) => {
            this.handleStreamTick(start);
          },

          onStreamStart: (roomName) => {
            this.handleStreamStart(roomName);
          },

          onStreamEnd: () => {
            this.cleanUp();
          },

          onCustomDataGet: (message) => {
            this.processCustomData(message);
            this.$emit('received-custom-data', message);
          },

          onDevicesReadyCallback: () => {
            const devices = this.streamModule.getDevices();

            if (devices.audioDevices.length <= 0) {
              SG.userError(
                'We can\'t find any available microphone in your system',
              );
              this.close();
            }

            if (devices.videoDevices.length <= 0) {
              SG.userError(
                'We can\'t find any available camera in your system',
              );
              this.close();
            }

            this.audioDevices = devices.audioDevices;
            this.videoDevices = devices.videoDevices;

            const defaultAudioInDeviceList = find(
              this.audioDevices,
              { deviceId: devices.defaultAudioDevice.deviceId },
            );

            if (!defaultAudioInDeviceList) {
              this.audioDevices.push(devices.defaultAudioDevice);
            }

            const defaultVideoInDeviceList = find(
              this.videoDevices,
              { deviceId: devices.defaultVideoDevice.deviceId },
            );

            if (!defaultVideoInDeviceList) {
              this.videoDevices.push(devices.defaultVideoDevice);
            }

            this.videoDeviceId = devices.defaultVideoDevice.deviceId;
            this.audioDeviceId = devices.defaultAudioDevice.deviceId;
          },

        }, (error) => {
          this.$emit('error', error);
        });
      });
    },

    async startStreaming() {
      const preferredServers = await this.getPreferredServers();
      const startStreamUrl = `${window.location.origin}/api/live/streams/`;
      const postData = {
        privacy: this.privacy,
        save_video: this.saveVideo,
        stream_type: this.streamType,
        preferred_servers: preferredServers,
      };
      const response = await axios.post(startStreamUrl, postData);
      this.stream = new Stream(response.data);
      this.streamModule.setConfig('serverData', {
        Token: response.data.token,
        Message: response.data.media_server_message,
      });

      this.streamModule.startStream();
      this.ready = false;
    },

    stopStreaming() {
      SG.debug('Stop Streaming');
      this.streamModule.stopStream();
    },

    async getPreferredServers() {
      // TODO: remove hardcoded endpoints
      const resp = await axios.get('/api/live/publisher/available-servers/', {
        params: { stream_type: this.streamType },
      });
      return urlSpeedTest(resp.data.Message.ServerList);
    },

    toggleMute() {
      SG.debug('Toggle mute');
      this.muted = !this.muted;
      this.streamModule.muteDevice('audio', !this.muted);
    },

    switchCamera() {
      let selectedCamIndex = this.selectedCamIndex();
      selectedCamIndex += 1;
      if (selectedCamIndex >= this.videoDevices.length) {
        selectedCamIndex = 0;
      }
      const videoDeviceId = this.videoDevices[selectedCamIndex].deviceId;
      this.videoDeviceId = videoDeviceId;
      SG.debug(
        `Switching devices to ${this.audioDeviceId} ${this.videoDeviceId}`,
      );
      this.streamModule.switchDevices(false, this.videoDeviceId);
    },

    selectedCamIndex() {
      return this.videoDevices.indexOf(
        this.videoDevices.find(
          (device) => device.deviceId === this.videoDeviceId,
        ),
      );
    },

    pingServer() {
      axios.post(this.stream.ping_url);
    },

    async handleStreamStart(roomName) {
      this.roomName = roomName;

      try {
        await this.markStarted();
      } catch {
        SG.userError('Error starting stream');
        this.stopStreaming();
        this.cleanUp();
        return;
      }

      this.streamModule.sendCustomMessage({
        msgtype: 'data.custom',
        to: ['transcoder'],
        data: {
          type: 'watermark',
          data: 'SuicideGirls',
        },
      });

      this.streaming = true;
      this.SET_STREAMING_TRUE();
      this.$emit('started');
    },

    async markStarted() {
      const data = {
        room_name: this.roomName,
      };

      await axios.post(this.stream.start_url, data);
    },

    handleStreamTick(start) {
      this.start = start;
      this.lastTick = Math.floor(Date.now() / 1000);

      if (this.streaming && this.lastTick % 3 === 0) {
        this.pingServer();
      }

      /**
       * Stream tick - still connected and broadcasting.
       */
      this.$emit('tick', this.lastTick);
    },

    processCustomData(message) {
      if (
        message.type === 'streamer.comment'
        || message.type === 'viewer.comment'
      ) {
        this.latestComment = message;
      }
    },

    cleanUp() {
      if (this.streaming) {
        axios.post('/api/live/publisher/end-stream/', {
          room_name: this.roomName,
        });
      }
      this.streaming = false;
      this.roomName = null;
      this.SET_STREAMING_FALSE();
      this.$emit('ended');
    },
  },
});
</script>

<style scoped lang="less">
h1.loading {
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

h1 {
  color: @background-grey;
  font-size: 14px;
  letter-spacing: 2px;
  margin-top: 0;
  padding-top: 50px;
  text-align: center;
  text-transform: uppercase;

  @media @phoneLandscape {
      padding-top: 25px;
  }
}

hr {
  border: 0;
  height: 1px;
  background-color: #707070 ;
  margin-top: 25px;
  margin-bottom: 25px;
  width: 90%;

  @media @landscape {
    width: 500px;
  }

  @media @phoneLandscape {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.video-feed {
  position: relative;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
}

#videowrap {
  opacity: 0.15;
  height: 100%;
  width: 100%;

  &.streaming {
    opacity: 1;
  }
}

#myvideo {
  bottom: 0;
  height: 100%;
  left: 0;
  margin: auto;
  object-fit: contain;
  object-position: center center;
  top: 0;
  width: 100%;
  z-index: 0;
}

.timer {
  bottom: 5px;
  color: black;
  display: inline-block;
  left: 0;
  margin: auto;
  opacity: 0.6;
  position: absolute;
  right: 0;
  text-align: center;
}

.publisher-preferences {
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 100;

  label {
    color: @background-grey;
    font-size: 12px;
    letter-spacing: 2px;
  }

  @media @phone-only {
    hr:last-child {
      display: none;
    }
  }
}

.btn {
  border-radius: 25px;
}

.c-options {
  align-items: center;
  display: flex;
  flex-direction: column;

  @media @phone-only {
    width: 100%;
  }

  label {
    float: left;
    margin-bottom: 5px;
    padding-top: 6px;
    text-align: left;
    width: 150px;
  }

  select {
    float: left;
    margin-bottom: 5px;
    margin-top: 5px;
    width: 175px;
  }

  .form-group {
    display: flex;

    @media @phone-only {
      width: 100%;
    }
  }

  button {
    border-radius: 25px;
  }
}

#devices {
  margin-top: 15px;
}

.stream-preference {
  color: #ffffff;
  float: left;
  font-family: gotham_boldregular;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 20px;
  text-transform: uppercase;

  .preference-option {
    display: inline-block;
    text-align: left;
    width: 200px;

    .radio-button {
      &:before {
        color: #ffffff;
        content: '○';
        font-size: 29px;
        line-height: 14px;
        position: relative;
        top: 5px;
      }

      &.selected:before {
        color: #a6d42e;
        content: '●';
      }
    }
  }

  @media @phone-only {
    display: block;
    float: none;
    text-align: left;
    width: 100%;

    .preference-option {
      display: block;
      padding: 0 15px 20px 15px;
      margin-left: 0;
      width: auto;

      &:last-child {
        padding-bottom: 0;
      }

      .radio-button {
        margin-left: 0;

        &:before {
          font-family: Arial;
          top: 4px;
        }
      }
    }
  }
}

.save-stream, .stream-type {
  margin-top: 20px;
}

.actions {
  bottom: 40px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  text-align: center;

  @media @beforeIpad {

    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    bottom: 10px;

    button {
      margin: 15px;
    }
  }

  .fullscreen {
    background: transparent;
    float: left;
    margin-left: 20px;
  }

  .go-live-cta {
    background-color: @background-grey;
    border-radius: 25px;
    color: #2c2c2c;
    padding: 15px 75px;
  }

  .end-stream {
    background-color: red;
    border-radius: 25px;
    color: @white;
  }

  .mute {
    border-radius: 25px;
    bottom: -5px;
    padding-left: 25px;
    padding-right: 25px;
    position: absolute;
    right: 20px;

    @media @phone-only {
      bottom: initial;
      right: initial;
      position: initial;
    }
  }

  .muted {
    background-color: @darker-green;
  }

  .switch-camera {
    border-radius: 25px;
    bottom: -5px;
    padding-left: 25px;
    padding-right: 25px;
    position: absolute;
    left: 20px;

    @media @beforeIpad {
      bottom: initial;
      left: initial;
      margin-bottom: 10px;
      position: initial;
    }
  }
}

.streaming {
  .fullscreen {
    bottom: 0;
    color: @white;
    font-size: 18px;
    left: 10px;
    position: absolute;
  }

  .actions {
    @media @phone-only {
      bottom: 40px;
      flex-direction: row;
      margin-bottom: initial;

      button {
        margin: initial;
      }

      .switch-camera {
        margin-bottom: initial;
      }
    }

    @media @phone-to-landscape {
      display: initial;
      .end-stream {
        width: 150px;
      }
      .mute {
        right: 5px;
      }
    }
  }
}
</style>
